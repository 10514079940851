<template>
	<div class="lx">
		<!-- 第一部分 -->
		<div class="complete">
			<div id="a8" class="a8" @click="getCheck('a8')"></div>
			<div id="a7" class="a7" @click="getCheck('a7')"></div>
		</div>

		<div class="yayaya">
			<div id="rt7div" class="rt7div" @click="sel"></div>
			<div  class="rt7" align="left">
				<img id="rt7img" src="../../assets/ysmimg/Zaehne_1.png" width="39" height="40" />
			</div>
		</div>
		
		
		
		<el-dialog title="收货地址" :visible.sync="dialogFormVisible" @close="destroy">
			<el-form :model="form">
				<el-form-item label="活动性质">
					<el-checkbox-group v-model="form.type">
						<el-checkbox label="全冠义齿" name="type"></el-checkbox>
						<el-checkbox label="部分冠义齿" name="type"></el-checkbox>
						<el-checkbox label="桥体" name="type"></el-checkbox>
						<el-checkbox label="种植体" name="type"></el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirmSel">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data:function(){
			return{
				dialogFormVisible:false,//控制dialog显示和隐藏的
				form:{
					type:[]//存储选择的复选框的中文内容
				},
				num:'',//每次单击的div
			}
		},
		methods: {
			//单击上面的div
			getCheck(num) {
				console.log(num);
				this.num=num;//保存当前div的id值
				//获取div中的内容
				let divCon=document.getElementById(num).innerHTML;
				if(divCon!=''){
					this.change2(divCon)
				}
				this.dialogFormVisible=true;
			},
			//dialog关闭的回到事件
			destroy(){
				
				this.form.type=[];//dialog关闭之后清空this.form.type中内容
				console.log(this.form.type);
			},
			//点击dialog确定按钮
			confirmSel(){
				 let arr=[];//放的英文内容
				 //1.如果复选框选择了内容就把内容放在对应的div中,没选就清空div内容
				 if(this.form.type.length>0){//选内容
					for(let i=0;i<this.form.type.length;i++){
						arr.push(this.change1(this.form.type[i]));
					}
					//将arr中英文部分变成字符串放在div中
					let str=arr.join(',');
					//将str内容放在div中
					document.getElementById(this.num).innerHTML=str;
				 }else{//没有选择内容
					 document.getElementById(this.num).innerHTML=""
				 }
				 //2.根据选择的内容设置哪些牙齿变成灰色,对应的div的单击事件禁用
				 if(arr.length>0){//选择内容了
					 if(this.num=="a8"){
							 if(arr.indexOf('P')!=-1){//存在
							    document.getElementById("rt7img").className="hui";
								document.getElementById("rt7div").setAttribute("style","pointer-events:none")
							 }else{//不存在
						        document.getElementById("rt7img").className="";
								document.getElementById("rt7div").removeAttribute("style");
							}
					 }
				}else{
					 	document.getElementById("rt7div").removeAttribute("style");
					  document.getElementById("rt7img").className="";
				 }
				this.dialogFormVisible=false;//关闭dialog
			},
			//把中文转换为应为
			change1(chinese){
				if(chinese=="全冠义齿"){
					return "C";
				}else if(chinese=="部分冠义齿"){
					return "PC";
				}else if(chinese=="桥体"){
					return "P";
				}else if(chinese=="种植体"){
					return "I";
				}
			},
			//把英文字符串转为英文数组
			change2(str){
				//把字符串str变成数组
				let arr=str.split(",");
				let arr1=[];
				for(let i=0;i<arr.length;i++){
					if(arr[i]=="C"){
						arr1.push("全冠义齿");
					}else if(arr[i]=="PC"){
						arr1.push("部分冠义齿");
					}else if(arr[i]=='P'){
						arr1.push("桥体");
					}
					else if(arr[i]=="I"){
						arr1.push("种植体");
					}
				}
				//把存储中文的数组arr1给this.form.type赋值
				this.form.type=arr1;
			},
			/* ======================================== */
			sel(){
				alert(1);
			},
			
		}
	}
</script>

<style scoped="scoped">
	.lx {
		width: 100%;
		height: 100%;
	}

	.complete {
		width: 50%;
		height: 8.125rem;
		background-color: lightgray;
		margin: 0px auto;
		padding: 0.3125rem;
	}

	.a8 ,.a7{
		/* 		min-width: 1.5625rem; */
		/* height: 2.5rem; */
		/* background-color: white; */
		width: 1.875rem;
		height: 1.875rem;
		background-color: white;
		display: inline-block;
		margin-left: 0.625rem;
	}
	.yayaya{
	
		width: 50%;
		height: 8.125rem;
		background-color: white;
		margin: 0px auto;
		padding: 0.3125rem;
			margin-top: 1.25rem;
			border: 1px solid red;
	}
	.rt7div{
		width: 2.4375rem;
		height: 3.125rem;
		background-color: lightgray;
	}
	.hui{
			filter: grayscale(100%);
		    -webkit-filter: grayscale(100%);
		    -moz-filter: grayscale(100%);
		    -ms-filter: grayscale(100%);
		    -o-filter:grayscale(100%);
		    filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
		    -webkit-filter: grayscale(1);
	}
</style>
